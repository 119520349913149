@import "./variable.module.scss";

.eventJoined {
  img {
    border: 2px solid $whiteColor;
    border-radius: 50%;
    margin-left: -10px;
  }
}

.eventImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 15px;
}

// @media only screen and (min-width: 900px) {
//   .eventImage {
//     width: 100%;
//     height: 100%;
//   }
// }

.heartIcon{
  width: 1.5em;
  height: 1.5em;
}