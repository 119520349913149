@font-face {
  font-family: "HellixRegular";
  src: url("../fonts/Hellix-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "HellixMedium";
  src: url("../fonts/Hellix-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "HellixBold";
  src: url("../fonts/Hellix-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("../fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsMedium";
  src: url("../fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsBold";
  src: url("../fonts/Poppins-Bold.ttf") format("truetype");
}
