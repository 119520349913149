$primaryColor: #2dc6ff;
$secondaryColor: #80cad3;
$tertiaryColor: #ff0000;
$blackColor: #111315;
$whiteColor: #fff;

$gradient: linear-gradient(
  180deg,
  rgba(223, 239, 239, 1) 2%,
  rgba(226, 241, 236, 1) 3%,
  rgba(239, 245, 231, 1) 97%,
  rgba(239, 239, 223, 1) 98%
);
