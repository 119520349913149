.css-1dejl2x-MuiInputBase-root-MuiInput-root:before,
.css-1fbjz3f::before {
  border-bottom: 0px !important;
  content: none;
}
.css-1dejl2x-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 0px !important;
  content: none;
}
.location_input {
  // border: 2px solid red;
  margin-left: 6px;
}

.css-1q60rmi-MuiAutocomplete-endAdornment {
  top: calc(50% - 20px) !important;
}

label + .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
  margin-top: 6px !important;
  padding: 0px 0px 6px 10px !important;
  color: white !important;
}

// label + .css-1dejl2x-MuiInputBase-root-MuiInput-root {
//   margin-top: 6px !important;
//   padding: 0px 0px 6px 10px !important;
//   color: white !important;
// }

.MuiAutocomplete-listbox {
  padding: 0;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAutocomplete-paper {
  margin-top: 10px;
  background-color: #20acac;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.list-location:hover{
background-color: #188181 !important;
}