/* import custom font and color */
@import "./fonts.scss";
@import "./variable.module.scss";

/* reset style  */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "HellixRegular";
}

a {
  text-decoration: none;
}

body {
  font-family: "HellixRegular";
}

.gradient {
  background: white;
}

.gradient1 {
  background: white;
}

// google map container
.mapContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.borders {
  border-radius: 40px 40px 0px 0px;
}
.icon-16 {
  width: 1em;
  height: 1em;
}

.iconWhite path {
  stroke: white !important;
}

#iconHover:hover .icon-16 path {
  stroke: white !important;
}

@media (max-width: 600px) {
  .icon-16 {
    width: 20px;
    height: 1em;
  }
}
.tags-swiper-md {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

// .css-4cnj93-MuiButtonBase-root-MuiButton-root:hover{
//   background-color: rgb(45, 198, 255) !important;
// }
.location-input {
  width: 100%;
}

.location-input .MuiInputBase-root.MuiOutlinedInput-root > fieldset {
  border-color: #878387 !important;
  opacity: 0;
  display: none;
}

.location-input .MuiInputBase-root.MuiOutlinedInput-root > input {
  padding: 0%;
}

.location-input .MuiFormControl-root.MuiTextField-root > label {
  color: black;
  opacity: 0;
  display: none;
}

.location-input
  .MuiButtonBase-root.MuiMenuItem-root.Mui-disabled.MuiMenuItem-gutters.Mui-disabled.MuiMenuItem-root.Mui-disabled.MuiMenuItem-gutters {
  display: none;
}

.location-input .MuiInputBase-adornedEnd .MuiAutocomplete-endAdornment {
  height: 100%;
  padding: 6px;
  border-left: 1px solid #d9d9d9;
  position: absolute;
  right: 6px;
  top: -3px;
}
.terms-line {
  font-size: 16px;
}
.terms-line span {
  cursor: pointer;
  text-decoration: underline;
}

.header-input {
  position: relative;
}

.header-input .MuiInputBase-input.MuiInput-input {
  font-size: 20.33px !important;
  font-weight: 400 !important;
  color: white !important;
}

.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before {
  border: none !important;
}

.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::after {
  border: none !important;
}

.text-poolside {
  color: #27c9c9;
  font-size: large;
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.date-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  background-color: white;
  opacity: 0.8;
  border-radius: 10px;
  position: absolute;
  top: 17px;
  left: 17px;
  font-size: 18px;
  font-weight: 700;
}

.bookmark {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: rgba($color: #ffff, $alpha: 0.8);
  border-radius: 7px;
  position: absolute;
  top: 17px;
  right: 17px;
}

.pathIcon svg {
  width: 23px;
  height: 23px;
}

.dreawer-shadow {
  background: #f8f8f9;
  height: 96%;
  position: relative;
  z-index: 1;
  border-radius: 59px;
}
// .Z-OUT{
//   left: -10% !important;
// }
.z1 {
  height: 80vh;
  width: 5%;
  position: absolute;
  top: 53px;
  left: 65%;
  background-color: #e6e6e6;
  opacity: 0.5;
  z-index: 1;
  border-radius: 5rem 0 0 5rem;
  animation: left1 225ms ease-out;

  @keyframes left1 {
    0% {
      left: 0;
    }
    60% {
      top: 65%;
    }
  }
}

.z2 {
  height: 76vh;
  width: 6%;
  position: absolute;
  top: 71px;
  left: 60%;
  background-color: #f2f2f2;
  z-index: 1;
  border-radius: 5rem 0 0 5rem;
  animation: left 225ms ease-out;

  @keyframes left {
    0% {
      left: 0;
    }
    60% {
      top: 60%;
    }
  }
}

select#category {
  border: none;
  background-color: transparent;
  margin-top: 5px;
  margin-left: -5px;
}

#category:focus-visible {
  border: none;
  outline: none;
}

#category::selection {
  border: none;
}

// .placeHolder::placeholder {
//   color: #000000;
//   opacity: 50%;
//   font-size: 14px;
//   font-weight: 400;
// }

select.placeHolder[placeHolder=""] {
  color: #000000;
  opacity: 50%;
}

.round {
  margin: 5px;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.checkbox {
  height: 16px;
  width: 16px;
  color: black;
  opacity: 50%;
  margin-right: 10px;
  border-radius: 2px;
}

.checkbox-label {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin-right: 5px;
  margin-bottom: -2px;
}

input[type="checkbox"].checkbox {
  accent-color: #32a4a4;
  color: #f2f2f2;
}

.gif svg {
  path[fill="rgb(0,200,133)"] {
    fill: #27c9c9;
  }
  path[stroke="rgb(0,200,133)"] {
    stroke: #27c9c9;
  }
}

.state-dropdown-filter {
  border: none;
  width: 75%;
  background-color: transparent;
  padding-left: 20px;
}

.state-dropdown-filter:focus-visible {
  border: none;
  outline: none;
}

.state-dropdown {
  border: none;
  width: 100%;
  background-color: transparent;
}

.state-dropdown:focus-visible {
  border: none;
  outline: none;
}

.tag-box {
  height: 24px;
  width: fit-content;
  border: 2px solid #f0f0f0;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  margin: 4px;
  padding: 15px;
  border-radius: 2px;
}

.tag-div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 50px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.description {
  font-size: 16px;
  border: none;
  outline: none;
  resize: none;
}

.gif-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
}

.input-style {
  font-size: 16px;
  border: none;
  outline: none;
}

.popup-div {
  height: 162px;
  width: 285px;
  box-shadow: 0px 2px 8px 0px #00000026;
  padding: 20px;
  background-color: #ffffff;
  z-index: 99;
}

.popup-text {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  opacity: 85%;
  padding-left: 28px;
  padding-top: 10px;
}

.popup-paragraph {
  font-size: 14px;
  font-weight: 700;
  color: #303030;
  padding-left: 12px;
}

.text-area {
  position: relative;
  width: 100%;
}

.tabs-div > div > div {
  justify-content: space-around !important;
}

.tabs-button {
  color: #eb5757 !important;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}

.map-link {
  width: 100%;
  font-family: PoppinsRegular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: -10px;
}

.main-mapdiv {
  width: 100%;
  height: 100vh;
  position: relative;
  top: 1px;
}

.map-header {
  position: absolute;
  top: 4%;
  z-index: 99;
  width: 100%;
}

.filter-model {
  position: relative;
  display: flex;
  justify-content: center;
}

.filter-model fieldset {
  border: none;
}

.filter-model input {
  box-sizing: border-box;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}

.filter-model input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  height: 100%;
}

.social-icon-box {
  width: 45px !important;
  height: 45px !important;
  border-radius: 15px !important;
  border: 1px solid #cccccc !important;
  box-shadow: 15px 0px 30px 0px #d3d4e240 !important;
}

.Logo-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  opacity: 1;
  transition: opacity 1.2s ease; /* Adjust the duration and easing as needed */
}

.Logo-page.fade-out {
  opacity: 0;
}
