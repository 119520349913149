@import "./variable.module.scss";

.user {
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
}

.eventCard {
  .eventThumb {
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    height: 165px;
    position: relative;
  }
  .calendarIcon {
    font-size: 16px;
    color: $whiteColor;
  }
}

@media only screen and (max-width: 900px) {
  .user {
    img {
      width: 58px;
      height: 60px;
    }
  }
}
